.locateButton {
	position: absolute;
	top: 80px;
	left: 2px;
	z-index: 999;
	width: 20px;
	height: 20px;
	cursor: pointer;
	display: none;
	padding: 5px;
	background: #fff;
	border: 2px solid rgba(0, 0, 0, 0.2);
	border-radius: 2px;
	box-shadow: 0 1px 5px rgb(0 0 0 / 65%);
}

.locateActive {
	fill: red;
}

.locatedAnimation {
	width: 17px;
	height: 17px;
	border: 1px solid #fff;
	border-radius: 50%;
	background: #2a93ee;
	animation: borderPulse 2s infinite;
}

@keyframes borderPulse {
	0% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
	}

	70% {
		box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
	}
}
