/* form specific formatting */
.form-group {
	display: -webkit-box;
	display: -ms-flexbox;
	/* display: flex; */
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	justify-content: flex-start;
	margin: 0 40px;
	text-align: left;
	button {
		margin: 20px 0px;
	}
}

.captcha {
	text-align: center;
	margin: 0 auto;
	justify-content: center;
	display: flex;
	margin-bottom: 20px;
}
/* .form-group.column {
	flex-direction: column;
} */
.form-group label {
	/* width: 100%; */
	margin-bottom: 10px;
	text-align: left;
	font-weight: normal;
	font-size: 90%;
	padding-left: 2%;
	background-color: #b21a82;
	padding: 6px 5px;
	/* border-radius: 5px; */
	margin-top: 15px;
	color: white;
	width: auto;
	display: inline-block;
	font-weight: 700;
}

/* .form-group label {
	flex: none;
	display: block;
	width: 66px;
	font-weight: bold;
	font-size: 1em;
	text-align: right;
	margin-right: 13px;
} */

.form-group label.right-inline {
	text-align: right;
	padding-right: 8px;
	padding-left: 10px;
	width: auto;
}

.form-group {
	.input-control,
	input,
	textarea {
		/* -webkit-box-flex: 1;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		display: block;
		margin-bottom: 10px;
		margin-right: 8px;
		padding: 4px;
		margin-top: -4px;
		text-align: left; */
		width: 100%;
		box-sizing: border-box;
	}
	input[type="checkbox"] {
		width: 100px;
		display: block;
	}
}
.form-group input.input-control {
	border: 1px solid #d1d1d1;
	padding: 8px;
	border-radius: 4px;
}

.button {
	background-color: #000000;
	color: #ffffff;
	border-radius: 50px !important;
	display: inline-block;
	padding: 1.154em 1.538em;
	position: relative;
	-webkit-border-radius: 0;
	border-radius: 0;
	z-index: 2;
	vertical-align: top;
	margin-top: 3px;
	margin-bottom: 3px;
	outline: 0;
	text-align: center;
	cursor: pointer;
	border: 1px solid transparent;
	box-sizing: border-box;
	word-wrap: break-word;
	-webkit-appearance: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-decoration: none;
	position: relative;
	overflow: hidden;
	-webkit-transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
	-moz-transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
	-o-transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
	-ms-transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
	transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	/* 
	font-family: Nunito; */
	font-weight: 700;
	font-style: normal;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 0.7px;

	margin-top: 30px;
}

@media (max-width: 768px) {
	.form-group {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.form-group .input-control {
		margin-top: 2px;
	}
	.form-group label.right-inline {
		text-align: left;
		padding-right: 0;
		padding-left: 0;
	}
}

/* .button {
	color: #fff;
	background-color: #5bc0de;
	border: 1px solid #46b8da;
	cursor: pointer;
} */
.button:hover {
	background-color: #b21a82;
}

.row {
	display: flex;
	@media (max-width: 776px) {
		display: block;

		.grve-text {
			text-align: center;
		}
	}
}

.column {
	width: 50%;
	@media (max-width: 776px) {
		width: 100%;
	}
}

.grve-text {
	text-align: left;
}

.center {
	text-align: center;
}
