.maps-wrapper {
	display: flex;
	justify-content: center;
	/* padding: 20px 0px; */
	margin-bottom: 20px;
}

.leaflet-container {
	width: 500px;
	height: 325px !important;
	background: transparent;
}

/* to move search outside of visible map */
.leaflet-top.leaflet-right {
	right: auto;
	top: -12px;
	left: -2px;
}

.leaflet-map-pane {
	// margin-top: 100px;
	position: relative;
	// top: 97px;
	padding-top: 100px;
}

.leaflet-top {
	top: 50px;
}

.leaflet-control-container {
	.leaflet-control-geocoder {
		width: 502px;
		border-bottom: 20px solid #efefef;
	}

	.leaflet-control-geocoder-icon {
		float: left;
		padding: 5px 22px;
	}
	.leaflet-control-geocoder-expanded .leaflet-control-geocoder-form {
		width: 90%;
		height: 29px;
	}
	.leaflet-control-geocoder-form input {
		width: 100%;
		height: 29px;
	}
	.leaflet-control-geocoder-alternatives {
		width: 100%;
		box-shadow: 0 8px 6px -6px #ccc;
		border-top: 1px solid #ccc;
		margin-top: 1px;
		li {
			width: 100%;
			padding-left: 20px;
		}
	}
}
