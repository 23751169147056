.receipt {
	li,
	ui {
		text-decoration: none;
		text-align: left;
		list-style: none;
		span {
			margin-left: 10px;
		}
	}
	li {
		margin-bottom: 10px;
	}
	h4 {
		font-weight: bold;
		text-decoration: underline;
	}
	.lower {
		margin-top: 30px;
		li {
			margin-bottom: 20px;
		}
		span {
			margin-left: 0px;
		}
	}
	margin-bottom: 50px;
}

.redMessage {
	color: red;
}
