.trackingComponent {
	display: flex;
	flex-direction: column;
	align-items: center;
	button,
	.standardButton {
		margin: 10px;
		width: 240px;
	}
	h2 {
		margin-bottom: 0px;
	}
	input.large {
		width: 300px;
	}
	input.search {
		background: url(../assets/pngegg.png);
		background-size: 5%;
		background-repeat: no-repeat;
		background-position: 4px 5px;
		padding: 3px 5px;
		padding-left: 27px;
		background-color: #fff;
		border: 0px;
		margin-bottom: 15px;
		height: 20px;
	}
	.standardButton {
		color: #434343;
		padding: 5px 40px;
		border-radius: 5px;
		border-width: 1px;
		border-color: rgb(157, 0, 0);
		background: #434343;
		border-color: #434343;
		border: 1px solid #434343;
		font-size: 13.333px;
		font-family: Arial, Helvetica, sans-serif;
		width: 160px;
	}
}

.gray .secondary {
	background: #fff;
}

section + section {
	border-top: 1px solid #ccc;
}

.textLeft {
	text-align: left;
}

.underline {
	text-decoration: underline;
}

.resultSection {
	margin-bottom: 15px;
}
