@font-face {
	font-family: "Lato";
	src: local("Lato"),
		url(../assets/fonts/Lato/Lato-Regular.ttf) format("truetype");
}

@font-face {
	font-family: "Lato-Bold";
	font-weight: 900;
	src: local("Lato"),
		url(../assets/fonts/Lato/Lato-Bold.ttf) format("truetype");
}

@font-face {
	font-family: "Lato";
	font-weight: 900;
	src: local("Lato"),
		url(../assets/fonts/Lato/Lato-Black.ttf) format("truetype");
}

@font-face {
	font-family: "Poppins";
	// font-weight: 900;
	src: local("Poppins"),
		url(../assets/fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
	font-family: "Nunito";
	// font-weight: 900;
	src: local("Nunito"),
		url(../assets/fonts/Nunito/Nunito-Bold.ttf) format("truetype");
}
@font-face {
	font-family: "Nunito-Regular";
	// font-weight: 900;
	src: local("Nunito"),
		url(../assets/fonts/Nunito/Nunito-Regular.ttf) format("truetype");
}
