.App {
	font-family: Nunito-Regular, Arial, sans-serif;
	text-align: center;
}

a {
	text-decoration: none;
}

b {
	font-family: Nunito, Arial, sans-serif;
}

h4 {
	font-family: Nunito, Arial, sans-serif;
}

body {
	margin: 0px;
	font-family: Nunito-Regular, Arial, sans-serif;
	text-align: center;
	background: #fff;
}

h1,
h2,
h3 {
	font-family: Poppins, Arial, sans-serif;
}

section {
	padding: 1em;
}
.left {
	text-align: left;
}

section.yellow {
	background: #e18cff;
	color: #000;
}

.yellowbackground {
	background: yellow !important;
}

section.gray {
	background: #f5f5f5;
}

.main-content {
	/* padding: 20px; */
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.alignLeft {
	text-align: left;
}

@media (max-width: 768px) {
	.main-content {
		// padding: 20px 8px;
	}
}

.message {
	width: auto;
	margin: 15px auto;
	padding: 15px;

	h3 {
		font-size: 24px;
		font-weight: 400;
		margin-top: 10px;
	}
}

.underline {
	text-decoration: underline;
}

.yellow {
	color: #000;
	background-color: #e18cff;
	border-color: #e18cff;
}

.green {
	background-color: #5db046 !important;
	border-color: #5db046;
}

.card.green {
	margin-top: 15px;
}

.absolute {
	position: absolute;
}
.fixed {
	position: fixed;
}
.dev {
	color: #fff;
	background: rgba(0, 0, 0, 0.527);
	top: 0px;
}

button {
	background: #b30000;
	color: #fff;
	padding: 5px 40px;
	border-radius: 5px;
	border-width: 1px;
	border-color: rgb(157, 0, 0);
	background: #434343;
	border-color: #434343;
	&:hover {
		cursor: pointer;
	}
	&:disabled {
		background: #aaa;
		background: #434343;
		border-color: #434343;
		color: #9c9c9c;
	}
	&:disabled:hover {
		cursor: default;
	}
}

.mainLogo {
	margin-top: 30px;
	width: 450px;
	margin-bottom: 20px;
	@media (max-width: 500px) {
		width: 100%;
	}
}

.MuiAccordionSummary-content {
	text-align: left;
}

.whiteText,
.whiteText a {
	color: #fff !important;
}

.locationWrapper {
	width: 296px;
	text-align: center;
	margin: 10px auto;
	border-radius: 15px;
	background: #fff;
	padding: 4px;
}

.secondary {
	background: #f5f5f5;
	color: #434343;
}

.marginLeft {
	margin-left: 15px;
}

.checkboxHolder {
	color: #666;
}

.checkboxHolder input {
	display: inline-block !important;
	width: 20px !important;
}

.center {
	text-align: center;
}

.small {
	font-size: 13px;
	display: block;
}

.trackerList li {
	margin-bottom: 10px;
}
